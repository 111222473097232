import styled from "styled-components";

const MEDIA = "@media only screen and (max-width: 900px)";

export const FooterContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 100px 0 20px;
  align-items: center;
  justify-content: space-between;

  ${MEDIA} {
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
  }
`;

export const FooterText = styled.p`
  ${MEDIA} {
    font-size: 11px;
  }
`;
