import React from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import Footer from "./Footer";
import "./layout.css";

import logo from "../images/logo.png";

const Layout = ({ children }) => {
  return (
    <>
      <Header
        logo={logo}
        links={[
          { name: "Início", to: "/#inicio" },
          { name: "Nosso Blog", to: "/blog" },
          { name: "Serviços e Cursos", to: "/servicos" },
          { name: "Nosso App", to: "/#app" },
        ]}
        actionButton={{ name: "Entre em Contato", to: "/#contato" }}
      />
      <div className="app-content">
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
