import React from "react";

import { FooterContainer, FooterText } from "./styles";

function Footer() {
  return (
    <FooterContainer>
      <FooterText>
        © {new Date().getFullYear()} Todos os direitos reservados | Martins
        Treinamentos
        <br />
        CNPJ: 29.810.019/0001-85
      </FooterText>
      <FooterText>
        Desenvolvido por{" "}
        <strong>
          <a
            target="_blank"
            href="https://github.com/davigsousa"
            rel="noreferrer"
          >
            DS
          </a>
        </strong>
        .
      </FooterText>
    </FooterContainer>
  );
}

export default Footer;
